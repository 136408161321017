import { usePageQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import Error from 'pages/_error';
import { OldEngineFeeds } from './OldEngineFeeds';
import { PageModule } from './PageModule';

export const ModuleStartPage = () => {
  const { adminUserType, isLoggedIn } = useMe();

  const modulePage = usePageQuery({
    variables: { slug: '/', previewUserType: adminUserType },
    fetchPolicy: adminUserType ? 'network-only' : 'cache-first',
  });

  if (modulePage.loading) {
    return null;
  }

  if (!modulePage.data?.page) {
    return <Error statusCode={404} />;
  }
  const { page } = modulePage.data;

  return (
    <>
      {page.modules.map((module, index) => (
        <PageModule module={module} key={module.__typename + index} index={index} />
      ))}
      {isLoggedIn && <OldEngineFeeds />}
    </>
  );
};
