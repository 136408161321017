import styled from 'styled-components';

export const IndexPageFeedWrapper = styled.section<{ noBackground?: boolean }>`
  margin: 0 auto;
  background: ${({ theme, noBackground }) => (noBackground ? 'transparent' : theme.primaryColor.appGray3)};
  padding: 42px 10px 24px;
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding-top: 32px;
  }
`;

export const StyledSearchWrapper = styled.div<{ large?: boolean }>`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ large }) => (large ? 'unset' : '1420px;')};
  padding: 0 6px;
`;

export const StyledFilterGames = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    justify-content: flex-start;
  }
`;

export const HiddenCheckbox = styled.input`
  display: none;
`;

export const IndexPageSignUpWrapper = styled.div`
  text-align: center;
`;

export const IndexPageWrapper = styled.div`
  .logo {
    margin-bottom: 40px;
  }

  .logo > img {
    width: calc(100% - 40px);
    margin: 5px 20px;
  }

  .message {
    margin-bottom: 50px;
    font-size: 1.4rem;
    text-align: center;
  }

  .h1 {
    font-weight: bold;
    font-size: 2rem;
  }

  .or {
    margin: 10px 0;
    font-size: 1.4rem;
  }

  .signin {
    font-size: 1.4rem;
  }

  .index-page-error {
    min-height: 50vh;
    width: calc(100% + 15px);
  }

  .news-wrapper {
    padding: 2rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    width: calc(100% - 30px);
    margin: 0;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }

  .news-wrapper > .news-text {
    display: block;
    float: left;
    width: 33%;
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.3rem;
    }
    @media (max-width: 1000px) {
      float: none;
      width: 100%;
      text-align: center;
    }
  }

  .news-wrapper > .news-video {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    position: relative;
    padding-top: 20%;
    width: 40%;

    @media (max-width: 1000px) {
      padding-top: 30%;
      width: 60%;
    }

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;
