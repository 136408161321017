import { Button, ButtonProps } from 'components/common/buttons/Button';

type ShowMoreButtonProps = {
  variety?: ButtonProps['variety'];
  size?: ButtonProps['size'];
  loading?: boolean;
} & Omit<ButtonProps, 'variety' | 'size' | 'busy' | 'loading' | 'disabled'>;

export const ShowMoreButton = ({
  variety = 'secondary',
  size = 'medium',
  loading,
  text,
  ...props
}: ShowMoreButtonProps) => {
  return <Button {...props} text={text ?? 'Show more'} variety={variety} size={size} disabled={loading} />;
};
