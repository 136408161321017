import React from 'react';
import styled from 'styled-components';
import { HeaderStyleProps } from './FeedHeader.types';

export const FeedHeader = styled.div<{ align?: React.CSSProperties['textAlign'] }>`
  text-align: ${({ align }) => align ?? 'left'};
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    margin-bottom: 32px;
  }
`;

export const FeedHeaderTitle = styled.h1`
  color: ${({ theme }) => theme.primaryColor.white};
  font-weight: 800;
  font-size: ${({ theme }) => theme.fontSize.title};
  @media screen and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSize.titleSmaller};
  }

  letter-spacing: 0px;
  color: ${({ theme }) => theme.primaryColor.white};
  margin: 0;
  text-transform: none;
  margin-bottom: ${({ theme }) => theme.margin.small};
  .early-access {
    background-color: ${({ theme }) => theme.primaryColor.green};
    color: ${({ theme }) => theme.primaryColor.white};
    font-size: 0.8rem;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    vertical-align: middle;
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.3rem 0.5rem;
    transform: rotate(-4deg);
    display: inline-block;
    margin-top: -0.4rem;
    margin-left: 0.8rem;
  }
`;

export const Header = styled.span<HeaderStyleProps>`
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
