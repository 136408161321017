import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export const useOnRouteChangeComplete = (callback: CallableFunction) => {
  const router = useRouter();
  const cb = useRef(callback);

  useEffect(() => {
    const { current } = cb;
    const callbackCheck = () => {
      if (!current) {
        return;
      }
      current();
    };
    router.events.on('routeChangeComplete', callbackCheck);

    return () => {
      router.events.off('routeChangeComplete', callbackCheck);
    };
  }, [callback, router.events]);
};
