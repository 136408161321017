import { Button } from 'components/common/buttons/Button';
import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import * as S from './SimpleFeed.styles';
import { SimpleFeedProps } from './SimpleFeed.types';

const SocialMediaBanner = dynamic(() => import('components/globals/SocialMediaBanner/SocialMediaBanner'), {
  ssr: false,
});

const feedPositions = {
  1024: 3,
  0: 5,
} as const;

const deviceItemsPerRowLimits = {
  1024: 4,
  650: 3,
  0: 2,
} as const;

export const SimpleFeed: React.FC<SimpleFeedProps> = ({
  children,
  banner,
  viewMoreHref,
  viewMoreAs,
  viewMoreTracking,
  dynamicPerDevice,
  testId = '',
  buttonText = 'View All',
}) => {
  let childrenArray = React.Children.toArray(children);
  const [index, setIndex] = useState(1);
  const [maxPerRow, setMaxPerRow] = useState<null | number>(null);

  const calculateItemsPerRow = (values: { [key: number]: number }) => {
    let newIndex = 1;
    Object.entries(values).forEach(([width, i]) => {
      if (window.innerWidth > Number(width)) {
        newIndex = i;
      }
    });
    return newIndex;
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setIndex(calculateItemsPerRow(feedPositions));
      setMaxPerRow(calculateItemsPerRow(deviceItemsPerRowLimits));
    }, 300);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (dynamicPerDevice && maxPerRow) {
    childrenArray = React.Children.toArray(children).slice(0, maxPerRow * 2 - (banner ? 1 : 0));
  }

  if (!banner) {
    return (
      <>
        <S.SimpleFeed data-cy={`SimpleFeedGameArray${viewMoreAs}`} data-testid={`SimpleFeed-${testId}`}>
          {childrenArray}
        </S.SimpleFeed>
        <S.ButtonWrapper>
          {viewMoreAs && viewMoreHref && (
            <Link passHref href={viewMoreHref} as={viewMoreAs} legacyBehavior>
              <Button
                onClick={viewMoreTracking}
                data-cy={`SimpleFeedGameArray${viewMoreAs}`}
                variety="secondary"
                size="medium"
                text={buttonText}
              />
            </Link>
          )}
        </S.ButtonWrapper>
      </>
    );
  }

  return (
    <>
      <S.SimpleFeed data-cy={`SimpleFeedGameArray${viewMoreAs}`} data-testid={`SimpleFeed-${testId}`}>
        {childrenArray.slice(0, index)}
        {!!childrenArray.length && <SocialMediaBanner key="SimpleFeedGameBanner" />}
        {childrenArray.slice(index, childrenArray.length)}
      </S.SimpleFeed>
      <S.ButtonWrapper>
        {viewMoreAs && viewMoreHref && (
          <Link passHref href={viewMoreHref} as={viewMoreAs} legacyBehavior>
            <Button
              onClick={viewMoreTracking}
              data-cy={`SimpleFeedGameArray${viewMoreAs}`}
              variety="secondary"
              size="medium"
              text={buttonText}
            />
          </Link>
        )}
      </S.ButtonWrapper>
    </>
  );
};
