import { FlexBox } from 'components/layouts/FlexBox';
import { ProjectCard } from 'components/cards/ProjectCard/ProjectCard';
import { SimpleFeed } from 'components/legacy/SimpleFeed/SimpleFeed';
import { FeedLoader } from 'components/common/SkeletonLoader/FeedLoader';
import { Paragraph } from 'components/typography';
import { ShowMoreButton } from 'components/common/buttons/ShowMoreButton';
import { GameKit, useProjectsQuery } from 'generated/graphql';
import { useOnRouteChangeComplete } from 'lib/useOnRouteChangeComplete';
import { useRouter } from 'next/router';
import { isTouch } from 'utils/isTouch';
import { OldEngineFeedProps } from './OldEngineFeed.types';

export const OldEngineFeed = ({
  first,
  sortBy,
  pagination,
  query,
  banner,
  viewMoreHref,
  viewMoreAs,
  viewMoreTracking,
  dynamicPerDevice,
  gamekit,
  includeDynamicTeasers,
  maxEngineVersion,
  minEngineVersion,
}: OldEngineFeedProps) => {
  const hasQuery = Boolean(query);
  const {
    data = {},
    loading,
    error,
    fetchMore,
    refetch,
  } = useProjectsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first,
      sortBy,
      filter: query,
      requireRelevancy: hasQuery,
      gamekit,
      maxEngineVersion,
      minEngineVersion,
    },
  });

  const router = useRouter();
  useOnRouteChangeComplete(refetch);

  if (error) {
    return null;
  }

  const games = data?.projects?.edges.map(({ node }) => node) ?? [];
  const pageInfo = data?.projects?.pageInfo;

  const skeletonQuantity = isTouch() && router.route === '/' ? 4 : first;

  const loadMoreEntries = async () => {
    await fetchMore({
      variables: {
        first: 40,
        cursor: pageInfo?.endCursor,
        filter: query,
        requireRelevancy: hasQuery,
        sortBy,
        gamekit: GameKit.Platformer,
      },
    });
  };

  return (
    <>
      {hasQuery && (
        <Paragraph size="medium">
          Search results for game &quot;<b>{query}</b>&quot; ({games.length})
        </Paragraph>
      )}

      {games.length === 0 && loading && <FeedLoader quantity={skeletonQuantity} />}

      <SimpleFeed
        dynamicPerDevice={dynamicPerDevice}
        banner={banner}
        includeDynamicTeasers={includeDynamicTeasers}
        viewMoreHref={viewMoreHref}
        viewMoreAs={viewMoreAs}
        viewMoreTracking={viewMoreTracking}>
        {(games || []).map(game => (
          <ProjectCard key={game.id} project={game} />
        ))}
      </SimpleFeed>

      <FlexBox justifyContent="center">
        {pagination && pageInfo?.hasNextPage && <ShowMoreButton loading={loading} onClick={loadMoreEntries} />}
      </FlexBox>
    </>
  );
};
