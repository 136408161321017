import styled from 'styled-components';

export const SimpleFeed = styled.div<{ large?: boolean; small?: boolean }>`
  width: 100%;
  max-width: ${({ large }) => (large ? 'unset' : '1420px;')};
  display: grid;
  grid-gap: ${({ small }) => (small ? '10px' : '15px')};
  grid-template-columns: repeat(${({ small }) => (small ? '8, calc(12.5% - 75px / 8)' : '4, calc(25% - 45px / 4)')});
  padding: 0 6px;
  margin: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.large}) {
    grid-template-columns: repeat(4, calc(25% - 45px / 4));
    grid-gap: 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    grid-template-columns: repeat(3, calc(33% - 10px / 2));
    grid-gap: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    grid-template-columns: repeat(2, calc(50% - 10px / 2));
    grid-gap: 10px;
  }
`;

export const ViewAllLink = styled.a`
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: 0.8rem 1.5rem;
  min-height: 33px;
  font-size: 0.9rem;
  background-color: #353740;
  background-position: center;
  background-size: cover;
  color: ${({ theme }) => theme.primaryColor.white} !important;
  white-space: nowrap;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: all 0.25s ease-out;

  &:hover {
    background-color: #474a58;
  }

  * {
    pointer-events: none;
  }

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.primaryColor.green};
  }
`;

export const ButtonGreen = styled.a`
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: 0.8rem 1.5rem;
  min-height: 33px;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.primaryColor.green};
  background-position: center;
  background-size: cover;
  color: ${({ theme }) => theme.primaryColor.white} !important;
  white-space: nowrap;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: all 0.25s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor.greenHover};
  }

  * {
    pointer-events: none;
  }

  i {
    margin-right: 0.3rem;
    font-size: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% - 12px);
  margin: auto;
  margin-top: 15px;
  flex-direction: row;
  * + * {
    margin-left: 15px;
  }
  > * {
    width: 162px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.medium}) {
      padding-top: 14px;
      padding-bottom: 14px;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
      margin-left: 0;
      width: 95vw;
    }
  }
`;
