import React from 'react';
import Link from 'next/link';
import { Spacer } from 'components/layouts/Spacer';
import * as S from './FeedHeader.styles';
import { FeedHeaderProps } from './FeedHeader.types';
import { Paragraph } from 'components/typography';

export const FeedHeader = ({ align, text, title, href, as: asRef }: FeedHeaderProps) => {
  return (
    <S.FeedHeader align={align}>
      {href && Boolean(href) && Boolean(asRef) && (
        <Link href={href} as={asRef} passHref>
          <S.Header hasLink>
            <S.FeedHeaderTitle data-cy={title}>{title}</S.FeedHeaderTitle>
          </S.Header>
        </Link>
      )}
      {(!href || !asRef) && (
        <S.Header>
          <S.FeedHeaderTitle>{title}</S.FeedHeaderTitle>
        </S.Header>
      )}
      {Boolean(text) && (
        <Paragraph size="medium" maxWidth={'700px'} textAlign="center" margin={'0 auto'}>
          {text}
        </Paragraph>
      )}
    </S.FeedHeader>
  );
};

export const SpacedFeedHeader: React.FC<FeedHeaderProps> = ({ align, text, title, href, as: asRef, children }) => {
  return (
    <>
      <Spacer height={48} />
      <FeedHeader align={align} text={text} title={title} href={href} as={asRef} />
      {children}
      <Spacer height={24} />
    </>
  );
};
