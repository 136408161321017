import { FeedHeader } from 'components/legacy/FeedHeader/FeedHeader';
import { OldEngineFeed } from 'views/feeds/OldEngineFeed';
import { FEATURE_TOGGLE_SHOW_OLD_ENGINE_FEEDS_ON_START_PAGE } from 'constants/featureToggle.constants';
import { GameKit, SortBy } from 'generated/graphql';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { IndexPageFeedWrapper } from 'views/pages/legacy/_index.styles';
import React from 'react';

export const OldEngineFeeds = () => {
  const showOldEngineFeeds = useFeatureToggle(FEATURE_TOGGLE_SHOW_OLD_ENGINE_FEEDS_ON_START_PAGE);

  const showPlatform = showOldEngineFeeds.isSubFeatureOn('platformer');
  const showPlayground = showOldEngineFeeds.isSubFeatureOn('playgrounds');
  const showShooter = showOldEngineFeeds.isSubFeatureOn('shooter');

  return (
    <>
      {showPlatform && (
        <IndexPageFeedWrapper>
          <FeedHeader href="/games/platformer" as="/platformer" align="center" title="Platformer" text="" />
          <OldEngineFeed
            first={8}
            sortBy={SortBy.Featured}
            viewMoreHref="/games/platformer"
            viewMoreAs="/platformer"
            banner
            gamekit={GameKit.Platformer}
            dynamicPerDevice
            includeDynamicTeasers
          />
        </IndexPageFeedWrapper>
      )}
      {showShooter && (
        <IndexPageFeedWrapper noBackground>
          <FeedHeader href="/games/shooter" as="/shooter" align="center" title="Shooter" text="" />
          <OldEngineFeed
            first={8}
            dynamicPerDevice
            sortBy={SortBy.Featured}
            gamekit={GameKit.Shooter}
            viewMoreHref="/games/shooter"
            viewMoreAs="/shooter"
          />
        </IndexPageFeedWrapper>
      )}

      {showPlayground && (
        <IndexPageFeedWrapper noBackground>
          <FeedHeader href="/games/playgrounds" as="/playgrounds" align="center" title="Playgrounds" text="" />
          <OldEngineFeed
            first={8}
            sortBy={SortBy.Featured}
            dynamicPerDevice
            gamekit={GameKit.Playground}
            viewMoreHref="/games/playgrounds"
            viewMoreAs="/playgrounds"
            banner
          />
        </IndexPageFeedWrapper>
      )}
    </>
  );
};
